<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="订单信息" name="1">
                <div>
                    <div class="row-box">
                        <div class="col-box">订单状态：{{reviewOrderInfo.payStateName||'暂无'}}</div>
                        <div class="col-box">订单号：{{reviewOrderInfo.orderNo||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">支付货币：{{reviewOrderInfo.payUnit||'暂无'}}</div>
                        <div class="col-box">支付渠道：{{reviewOrderInfo.payWayName||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">下单时间：{{reviewOrderInfo.orderTime||'暂无'}}</div>
                        <div class="col-box">支付时间：{{reviewOrderInfo.payTime||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">应付外币金额：
                        <template v-if="reviewOrderInfo.payUnit==='CNY'">暂无</template>
                        <template v-else> {{ reviewOrderInfo.payUnit }} {{ reviewOrderInfo.payAbleForeignCurrencyMoney }}
                        </template>
                        </div>
                        <div class="col-box">优惠外币金额：
                            <template v-if="reviewOrderInfo.payUnit === 'CNY'">暂无</template>
                            <template v-else>
                                {{ reviewOrderInfo.payUnit }}
                                {{ reviewOrderInfo.discountForeignCurrencyMoney }}
                            </template>
                    </div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">实付外币金额：
                            <template v-if="reviewOrderInfo.payUnit === 'CNY'">暂无</template>
                            <template v-else>
                                {{ reviewOrderInfo.payUnit }}{{ reviewOrderInfo.paidForeignCurrencyMoney }}</template>
                        </div>
                        <div class="col-box">应付金额：
                            {{ reviewOrderInfo.orderMoney? ` CNY ${reviewOrderInfo.orderMoney}`: 0}}
                        </div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">优惠金额：
                            {{ reviewOrderInfo.discountMoney? ` CNY ${reviewOrderInfo.discountMoney}`: 0}}
                        </div>
                        <div class="col-box">实付金额：
                            {{ reviewOrderInfo.payMoney? ` ${reviewOrderInfo.payUnit} ${reviewOrderInfo.payMoney}`: 0}}
                        </div>
                    </div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "orderInfo",
        props:{
            reviewOrderInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            width: 50%;
            height: 100%;
            word-break: break-word;
            /*background-color: red;*/
        }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
