import request from '@/utils/request'
let OMS_URL = process.env.VUE_APP_OMS_API

export  function getOrderInfo(params) {
    return request({
        url:'/mobile/order/getOrderInfo',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

export  function toValidate(params) {
    return request({
        url:'/mobile/order/validate',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

//订单信息
export function getNewOrderInfo(params) {
    return  request({
        url:'/mobile/order/getNewOrderInfo',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}


export function getSalesList(params) {
    return request({
        url:'/mobile/order/getSalesList',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

export function getAdviserList(params) {
    return request({
        url:'/mobile/order/getAdviserList',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

//获取业务流
export function getFlow(params) {
    return request({
        url:'/mobile/order/getFlow',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

//订单审核
export function addAuditRecord(data) {
    return request({
        url:'/mobile/order/addAuditRecord',
        method:'post',
        baseURL: OMS_URL,
        data
    })
}

// 订单委派
export function orderDelegate(data) {
    return request({
        url:'/mobile/order/orderDelegate',
        method:'post',
        baseURL: OMS_URL,
        data
    })
}

//退款订单申请详情
export function getOrderDetails(params) {
    return request({
        url:'/mobile/order/getOrderDetails',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

//退单审核
export function addApplyRefundProcessInfo(data) {
    return request({
        url:'/mobile/order/addApplyRefundProcessInfo',
        method:'post',
        baseURL: OMS_URL,
        data
    })
}


export function getOrderInfoNoPushNo(params) {
    return request({
        url:'/order/getOrderInfo',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}

//
export function getNewOrderInfoNoPushNo(params) {
    return request({
        url:'/order/getNewOrderInfo',
        method:'get',
        baseURL: OMS_URL,
        params
    })
}














