<template>
    <div>
        <van-dialog
                ref="dialog"
                v-model="dialogVisiblle"
                title="委派"
                show-cancel-button
                :lockScroll="false"
                :before-close="cancelBtn"
        >
        <van-skeleton
            :row="10"
         :loading="vanLoading">
        <van-form ref="formSales" label-width="100px" :inline="false">
            <div v-if="isSale">
                <span class="pad-left10">市场端</span>
                <div class="pad-left10 spantxt">选择市场组顾问</div>
                <van-field v-model="formSales.sale"
                           @click="showSalesPicker = true"
                           readonly clickable
                           clearable
                           @click-right-icon.stop = "clearSales"
                           placeholder="请选择(工号+名称)">
<!--                    :right-icon="`${formSales.sale?'clear':'none'}`"-->
                </van-field>
                <van-popup v-if="showSalesPicker" v-model="showSalesPicker" round position="bottom">
                    <van-picker
                            title="市场端"
                            v-if="showSalesPicker"
                            show-toolbar
                            :columns="salesList"
                            @confirm="onConfirmSales"
                            @cancel="onCancel"
                    ></van-picker>
                </van-popup>
            </div>

            <div v-if="businessList.length>0">
                <span class="pad-left10">业务端</span>
                <div v-for="(item,index) in businessList" :key="index">
                    <span class="pad-left10 spantxt">业务流：{{item.flowName}}</span>
                    <div class="pad-left10 spantxt">选择业务组顾问</div>
                    <van-field v-model="item.personText"
                               @click="businessClick(item,index)"
                               readonly clickable
                               clearable
                               @click-right-icon.stop = "clearBusiness(item,index)"
                               placeholder="请选择（工号+名称）">
                    </van-field>
<!--                    :right-icon="`${item.personText?'clear':'none'}`"-->

                    <van-popup v-if="showBusinessPicker" v-model="showBusinessPicker" round position="bottom">
                        <van-picker
                                title="业务端"
                                v-if="showBusinessPicker"
                                show-toolbar
                                :columns="adviserList"
                                @confirm="onConfirmBusiness"
                                @cancel="onCancel"
                        ></van-picker>
                    </van-popup>
                </div>
            </div>
        </van-form>
    </van-skeleton>
        </van-dialog>
    </div>
</template>

<script>
    import { getSalesList,
            getAdviserList,
            getFlow,
            addAuditRecord,
            orderDelegate
    } from '@/api/myOrderDetails'
    export default {
        name: "delegateDialog",
        props:{
            showDelegateDialogVisible:{
                type:Boolean,
                default:false,
                required:true
            },
            isSale:{
                type:Boolean,
                default:true,
                required:true
            },
            orderNo:{
                type:String,
                default:'',
                required:true
            },
            pushNo:{
                type:String,
                default:'',
                required:true
            }
        },
        data(){
            return{
                dialogVisiblle:this.showDelegateDialogVisible,
                vanLoading:false,
                formSales:{
                    sale:'',
                },
                saleId:'',
                currentIndex:'',
                showSalesPicker:false,
                showBusinessPicker:false,
                salesList:[{
                    text:'111',
                    value:0
                },{
                    text:'222',
                    value:1
                },{
                    text:'333',
                    value:2
                }],
                obj:{
                  value:''
                },
                businessList:[],
                state:{
                    value1: 0,
                    showPicker: false,
                },
                adviserList:[
                    { text: '选择市场顾问', value: 0 },
                    { text: '新款商品', value: 1 },
                    { text: '活动商品', value: 2 },
                ]
            }
        },
        created() {
        this.vanLoading = true
         let res1 =  this.getSalesList()
         let res2 = this.getAdviserList()
         Promise.all([res1,res2]).then(res=>{
            this.vanLoading = false
         })
          this.getFlow()
        },
        methods:{
            //获取销售人员
            async getSalesList(){
                await getSalesList().then(res=>{
                    this.salesList = res.data.map((item,index)=>{
                       let { userNumber,nickName,userId } = item
                        item.text = userNumber?(userNumber+'+'+nickName):nickName
                        item.value = userId
                        return item
                    })
                })
            },
            //获取顾问人员
            async getAdviserList(){
                await getAdviserList().then(res=>{
                    this.adviserList = res.data.map((item,index)=>{
                        let { userNumber,nickName,userId } = item
                        item.text = userNumber?(userNumber+'+'+nickName):nickName
                        item.value = userId
                        return item
                    })
                })
            },
            //获取业务流程
            getFlow(){
                getFlow({
                    orderNo:this.orderNo
                }).then(res=>{
                    console.log('res.rows====>',res.rows);
                    this.businessList  = res.rows
                    //     .map((item,index)=>{
                    //     item.personId = this.obj.value
                    //     return item
                    // })
                    console.log(this.businessList);
                })
            },
            onConfirmSales(e){
                // console.log('e====>',e)
                this.formSales.sale = e.text
                this.saleId = e.value
                this.onCancel()
            },
            onConfirmBusiness(e){
                // this.formSales.business = e.text
                console.log('e.text====>',e.text);
                this.businessList[this.currentIndex].personText = e.text
                this.businessList[this.currentIndex].personId = e.value
                this.onCancel()
            },
            businessClick(item,index){
                this.currentIndex = index
                this.showBusinessPicker = true
            },
            onCancel(){
                this.showSalesPicker = false
                this.showBusinessPicker = false
            },
            submit(){
                let flag = this.businessList.every((item,index)=>{
                    return  item.personId
                })
                if(!flag||!this.saleId){
                    this.cancelBtn()
                    return
                }
                this.$refs['dialog'].loading.confirm = true
                orderDelegate({
                    orderNo:this.orderNo,
                    saleId:this.saleId,
                    flowList:this.businessList,
                    pushNo:this.pushNo
                }).then(res=>{
                    if(res.code==500&&res.success== false){
                        let txt = res.msg
                        this.$failToast(txt)
                        return
                    }
                        this.$successToast('委派成功')
                })
            },
            cancelBtn(action, done){
                if (action === "confirm") {//点击确定按钮
                    let flag = this.businessList.every((item,index)=>{
                    return  item.personId
                })
                if(!flag){
                    this.$emit('update:showDelegateDialogVisible',false)
                    return done()
                }
                this.$refs['dialog'].loading.confirm = true
                orderDelegate({
                    orderNo:this.orderNo,
                    saleId:this.saleId,
                    flowList:this.businessList,
                    pushNo:this.pushNo
                }).then(res=>{
                    if(res.code==500&&res.success== false){
                        let txt = res.msg
                        this.$failToast(txt)
                        this.$refs['dialog'].loading.confirm = false
                        this.$emit('update:showDelegateDialogVisible',false)
                        this.$emit('refreshData')
                        return done();//阻止关闭
                    }else{
                        this.$successToast('委派成功')
                        this.$refs['dialog'].loading.confirm = false
                        this.$emit('update:showDelegateDialogVisible',false)
                        this.$emit('refreshData')
                        return done();//阻止关闭
                    }

                })
                }
                if(action === "cancel"){
                    this.$emit('update:showDelegateDialogVisible',false)
                    return done();//阻止关闭
                }
            },
            clearSales(){
                this.formSales.sale = ''
                this.saleId = ''
            },
            clearBusiness(item,index){
                console.log(item, index);
                this.businessList[index].personText = ''
                this.businessList[index].personId = ''
            }
        }
    }
</script>

<style scoped lang="scss">

    .pad-left10{
        padding-left: 10px;
    }
    .spantxt{
        font-size: 14px;
        margin-top: 5px;
    }
    /deep/.van-dropdown-menu__item{
         -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }
    .van-dialog{
        min-height: 500px;
    }
    /deep/.van-dialog__footer {
        width: 100%;
        position: fixed;
        bottom: 0;
    }
</style>
