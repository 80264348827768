<template>
    <div>
        <van-dialog
                    ref="dialog"
                    v-model="dialogVisible"
                    :title="title"
                    show-cancel-button
                    :confirmButtonText="`${arg==1?'审核通过':'有效'}`"
                    :cancelButtonText="`${arg==1?'审核驳回':'无效'}`"
                    :before-close="cancelBtn"
        >
          <!--  @confirm="confirm"
            @cancel="cancelEvent"-->
            <div class="content">
                <div class="titleInfo">订单信息</div>
                <div class="formInfo">
                    <div>
                        <span>Order ID：</span><span>{{reviewInfo.orderNo||'暂无'}}</span>
                    </div>
                    <div>
                        <span>订单类型：</span><span>{{reviewInfo.serviceType||'暂无'}}</span>
                    </div>
                    <div>
                        <span>客户名：</span><span>{{reviewInfo.friendName||'暂无'}}</span>
                    </div>
                    <div>
                        <span>绑定手机号：</span><span>{{reviewInfo.phone||'暂无'}}</span>
                    </div>
                    <div>
                        <span>国家/城市：</span><span>{{reviewInfo.nationCityName||'暂无'}}</span>
                    </div>
                    <div>
                        <span>收费状态：</span><span>{{reviewInfo.chargeStatus||'暂无'}}</span>
                    </div>
                    <div>
                        <span>拼团状态：</span><span>{{reviewInfo.groupStatus||'暂无'}}</span>
                    </div>
                    <div>
                        <span>下单时间：</span><span>{{reviewInfo.orderTime||'暂无'}}</span>
                    </div>
                    <div>
                        <span>支付时间：</span><span>{{reviewInfo.payTime||'暂无'}}</span>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import { addAuditRecord } from '@/api/myOrderDetails'
    export default {
        name: "examineDialog",
        props:{
            showExamineDialogVisible:{
                type:Boolean,
                default:false,
                required:true
            },
            arg:{
                type:Number,
                default:1,
                required: true
            },
            reviewInfo:{
                type:Object,
                default(){
                    return{
                    }
                },
                required:true
            },
            pushNo:{
                type:String,
                default:'',
                required: true
            }
        },
        data(){
            return{
                loading:false,
                dialogVisible: this.showExamineDialogVisible
            }
        },
        computed:{
          title(){
             if(this.arg==1){
                 return '财务审核'
             }else {
                 return '有效性审核'
             }
          }
        },
        created() {
        },
        mounted() {
        },
        methods:{
            // confirm(){
            //     let txt = this.arg == 1?'审核已通过':'审核已驳回'
            //     let auditType
            //     if(this.arg==1){
            //         auditType = 1
            //     }else{
            //         auditType = 2
            //     }
            //     addAuditRecord({
            //         orderNo:this.reviewInfo.orderNo,
            //         auditType:auditType,
            //         auditResult:1,
            //         pushNo:this.pushNo
            //     }).then(res=>{
            //         this.cancelBtn()
            //         this.$emit('refreshData')
            //     })
            // },
            // cancelEvent(){
            //     let txt = this.arg == 1?'审核已通过':'审核已驳回'
            //     let auditType
            //     if(this.arg==1){
            //         auditType = 1
            //     }else{
            //         auditType = 2
            //     }
            //     addAuditRecord({
            //         orderNo:this.reviewInfo.orderNo,
            //         auditType:auditType,
            //         auditResult:2,
            //         pushNo:this.pushNo
            //     }).then(res=>{
            //         this.cancelBtn()
            //     })
            // },
            cancelBtn(action, done){
                if(action=='confirm'){
                    let txt = this.arg == 1?'审核已通过':'审核已驳回'
                        let auditType
                        if(this.arg==1){
                            auditType = 1
                        }else{
                            auditType = 2
                        }
                        this.$refs['dialog'].loading.confirm = true
                        addAuditRecord({
                            orderNo:this.reviewInfo.orderNo,
                            auditType:auditType,
                            auditResult:1,
                            pushNo:this.pushNo
                        }).then(res=>{
                            if(res.success){
                                this.$successToast('审核通过')
                                this.$refs['dialog'].loading.confirm = false
                                this.$emit('update:showExamineDialogVisible',false)
                                this.$emit('refreshData')
                                return done()
                            }
                            if(res.code==500&&res.success==false){
                                this.$failToast(res.msg)
                                this.$refs['dialog'].loading.confirm = false
                                this.$emit('update:showExamineDialogVisible',false)
                                this.$emit('refreshData')
                                return done()
                            }

                        })
                }
                if(action=='cancel'){
                    let txt = this.arg == 1?'审核已通过':'审核已驳回'
                    let auditType
                    if(this.arg==1){
                        auditType = 1
                    }else{
                        auditType = 2
                    }
                    this.$refs['dialog'].loading.cancel = true
                    addAuditRecord({
                        orderNo:this.reviewInfo.orderNo,
                        auditType:auditType,
                        auditResult:2,
                        pushNo:this.pushNo
                    }).then(res=>{
                        if(res.success){
                            this.$successToast('审核驳回')
                            this.$refs['dialog'].loading.cancel = false
                            this.$emit('update:showExamineDialogVisible',false)
                            this.$emit('refreshData')
                            return done()
                        }
                        if(res.code==500&&res.success==false){
                            this.$failToast(res.msg)
                            this.$refs['dialog'].loading.cancel = false
                            this.$emit('update:showExamineDialogVisible',false)
                            this.$emit('refreshData')
                            return done()
                        }


                    })
                }
                // this.$refs['dialog'].loading.cancel = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .content{
        padding:10px;
        .titleInfo{
            font-weight: 700;
            margin-bottom: 10px;
        }
        .formInfo{
            padding-left: 10px;
            div{
                margin-bottom: 10px;
            }
        }
    }

</style>
