<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="用户信息" name="1">
                <div>
                    <div class="row-box">
                        <div class="col-box">用户ID：{{userInfo.customerId||'暂无'}}</div>
                        <div class="col-box">注册方式：{{userInfo.registerWay||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">客户姓名：{{userInfo.nickName||'暂无'}}</div>
                        <div class="col-box">性别：{{userInfo.sex||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">绑定手机号码：{{userInfo.phone||'暂无'}}</div>
                        <div class="col-box">绑定微信：{{userInfo.bindWechatStatus||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">绑定邮箱：{{userInfo.bindEmailStatus||'暂无'}}</div>
                        <div class="col-box">专属邀请码：{{userInfo.inviteCode||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">注册时间：{{userInfo.registerTime||'暂无'}}</div>
                        <div class="col-box">CP推荐联系人：{{userInfo.connectorName||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box all-col">邀请人：{{userInfo.inviteCustomerName || '暂无'}}</div>
                    </div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "userInfo",
        props:{
            userInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;

        .col-box{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 50%;
        height: 100%;
        word-break: break-word;
        /*background-color: red;*/
    }
        .all-col{
            width: 100%;
        }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
