<template>
    <div >
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="业务类型及内容" name="1">
                <div v-if="mainService.mainService && mainService.mainService.length>0">
                    <div v-for="(item,index) in mainService.mainService" :key="item.type">
<!--                        <div class="row-box">-->
<!--                            <div class="col-box all-col" style="font-weight: 700">{{mainService.type}}</div>-->
<!--                        </div>-->
                        <div class="row-box vip" >
                            <div class="col-box">
                                {{ item.serviceName }}<!--<span style="margin-left: 20px"></span>-->
                            </div>
                            <div class="col-box">服务费用：
                                <span>{{ parsePrice(item.discountPrice, item.priceUnit) }}</span>
                            </div>
                            <div class="col-box" v-if="item.origPrice&&(item.discountPrice<item.origPrice)">
                                <span   v-if="item.origPrice!='免费'"
                                        style="text-decoration: line-through; margin-left: 20px">
                                    ({{item.priceUnit}} {{ item.origPrice }})</span>
                            </div>
                        </div>
                    </div>
                </div>
<!--  增值服务-->
              <div v-if="addService && addService.length>0">
                <div class="row-box" v-for="(item,index) in addService" :key="item.serviceKey">
                    <div class="col-box">
                        <span >{{ item.serviceName }}</span>
                    </div>
                  <div class="col-box">服务费用：
                    <span>{{ parsePrice(item.discountPrice, item.priceUnit) }}</span>
                  </div>
                    <div class="col-box" v-if="item.origPrice&&(item.discountPrice<item.origPrice)">
                        <span  v-if="item.origPrice!='免费'"
                                style="text-decoration: line-through; margin-left: 20px">
                            ({{item.priceUnit}} {{ item.origPrice }})</span>
                    </div>
                </div>
              </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "typeAndCont",
        props:{
            mainService:{
                type:[Object,Array],
                default(){
                    return {}
                },
                required:true
            },
          addService:{
            type: Array,
            default: [],
            required:true
          }
        },
        data(){
            return{
                activeName:'1',
            }
        },
        created() {

        },
        methods:{
          parsePrice(v, unit){
            if (v == 0) {
              return '免费'
            } else {
              return unit + v
            }
          }
        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        /*justify-content: space-between;*/
        color:#000;

        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            max-width: 33%;
            height: 100%;
            margin-left: 10px;
            word-break: break-word;
            /*background-color: red;*/
        }
        .all-col{
            width: 100%;
        }
    }
    .vip{
        .col-box{
            width: 40%;
        }
    }


    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
