<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="使用优惠券" name="1">
                <div v-if="usedCouponInfo.length>0">
                    <div v-for="(item, index) in usedCouponInfo" :key="index">
                        <div class="title">优惠券{{index+1}}</div>
                        <div class="row-box">
                            <div class="col-box">优惠券名称： {{ item.couponName || '暂无' }}</div>
                            <div class="col-box">适用城市：{{ item.city || '暂无' }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">使用服务：{{ item.productName || '暂无' }}</div>
                            <div class="col-box">获取方式：{{ item.getType || '暂无' }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">兑换码：{{ item.exchangeCode || '暂无' }}</div>
                            <div class="col-box">到期时间：{{ item.couponDateEnd || '暂无' }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">获取时间：{{ item.createTime || '暂无' }}</div>
                            <div class="col-box">使用时间：{{ item.couponUseTime || '暂无' }}</div>
                        </div>
                    </div>
                </div>
                <div style="color: #000" v-else>暂无</div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "useCoupon",
        props:{
            usedCouponInfo: {
                type: [Object, Array],
                required: true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .title{
        font-weight: 700;
        color:#000;
    }
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 50%;
        height: 100%;
        word-break: break-word;
        /*background-color: red;*/
    }
    .all-col{
        width: 100%;
    }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
