<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="拼团拉新活动信息" name="1">
                <div>
                    <div class="title">拼团编号：{{activityDetails.groupCode}}</div>
                    <div class="row-box">
                        <div class="col-box">最大成团人数：{{ activityDetails.placesNumMax }}</div>
                        <div class="col-box">最小成团人数：{{ activityDetails.placesNumMin }}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">成团有效时间：{{ activityDetails.activityEffectiveTime }}</div>
                        <div class="col-box">租期限制：{{ activityDetails.rentRequire }}{{ activityDetails.rentRequireUnit|rentRequireUnitFilter }}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">规定返现领取时间：{{ activityDetails.makeDate }}</div>
                        <div class="col-box">活动时间：{{ activityDetails.activityTime }}</div>
                    </div>

                    <div v-for="(item, idx) in activityDetails.requireList" :key="item.supplierType">
                        <div class="title">返现规则{{idx+1}}</div>
                        <div class="row-box">
                            <div class="col-box">活动类型：{{ item.supplierType }}</div>
                            <div class="col-box">成团奖励：{{ item.requireContent }}</div>
                        </div>
                    </div>
                    <div class="title">拼团详情</div>
                    <div class="row-box">
                        <div class="col-box">拼团状态：{{ activityDetails.buyStatus }}</div>
                        <div class="col-box">拼团人数：{{ activityDetails.buyNum }}</div>
                    </div>
                    <div v-for="(item, idx) in activityDetails.buyUserSettList" :key="'group_'+idx">
                        <div class="title">结算详情</div>
                        <div class="row-box">
                            <div class="col-box">结算金额：{{ item.payUnit }}{{ item.paidAmount }}</div>
                            <!--                        <div class="col-box">上传附件：</div>-->
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">结算时间：{{ item.makeDate }}</div>
                        </div>
                    </div>

                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "newActivityInfo",
        filters: {
            rentRequireUnitFilter(val) {
                if (val === 1) {
                    return '周'
                }
                if (val === 2) {
                    return '月'
                }
                if (val === 3) {
                    return '年'
                }
            }
        },
        props:{
            activityDetails:{
                type:Object,
                default(){
                    return{}
                },
                // required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .title{
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: 5px;
        color:#000;
    }
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            width: 50%;
            height: 100%;
            word-break: break-word;
            /*background-color: red;*/
        }
        .all-col{
            width: 100%;
        }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
