<template>
    <div>
        <van-collapse v-if="applyInfo" v-model="activeName" accordion>
            <van-collapse-item title="申请明细" name="1">
                <div v-if="applyInfo">
                    <div class="row-box">
                        <div class="col-box all-col">申请编号：{{applyInfo.applyCode||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box all-col">房源编号：{{applyInfo.houseCode||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box all-col">楼盘名称： {{houseInfo.houseName||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box all-col">房间名称： {{houseInfo.roomName||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box all-col">房源方：{{ houseInfo.brandName || houseInfo.cooperateName ||'暂无'}}</div>
                    </div>

                    <div v-if="applyStudentInfo">
                        <div class="title">申请信息</div>
                        <div class="row-box">
                            <div class="col-box all-col">公寓名称：{{ applyStudentInfo.houseName }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">房间类型：{{ applyStudentInfo.roomType }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">房间名称：{{ applyStudentInfo.roomName }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">周租金：{{applyStudentInfo.weeklyRent?applyStudentInfo.moneyUnit + "" + applyStudentInfo.weeklyRent:"免费"}}</div>
                            <div class="col-box">面积：{{ applyStudentInfo.area ||'暂无'}}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">楼层：{{ applyStudentInfo.floor||'暂无' }}</div>
                            <div class="col-box">朝向：{{ applyStudentInfo.direction ||'暂无'}}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">租期：{{ applyStudentInfo.leaseDay || '' + "" + applyStudentInfo.leaseDayUnit }}</div>
                            <div class="col-box">起租时间： {{ applyStudentInfo.leaseDateStr ||'暂无'}}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">抵达时间：{{ applyStudentInfo.arrivalDateStr || "暂无" }}</div>
                            <div class="col-box">航班号：{{ applyStudentInfo.flightNumber || "暂无" }}</div>
                        </div>
                    </div>

                    <div v-if="applyHomeInfo">
                        <div class="title">申请信息</div>
                        <div class="row-box">
                            <div class="col-box">起租日期：{{applyHomeInfo.leaseDateStr||'暂无'}}</div>
                            <div class="col-box">租期：{{ applyHomeInfo.leaseDay + ' ' + applyHomeInfo.leaseDayUnit||'暂无'}}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">
                              申请周租金：{{ applyHomeInfo.moneyUnit + ' ' + applyHomeInfo.weeklyRent ||'暂无'}}
                            </div>
                        </div>
                      <div class="row-box" v-if="houseInfo.isDnf == 2">
                        <div class="col-box all-col">
                          房源周租金: <span>{{dnfJsonObj.price}} = {{dnfJsonObj.origin}}(源租金) + {{dnfJsonObj.extra}}(加价申请) + {{dnfJsonObj.furniture}}(家具)</span>
                        </div>
                      </div>
                        <div class="row-box">
                            <div class="col-box all-col">说明为什么你喜欢这套房源： {{ applyHomeInfo.whyLike ||'暂无'}}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">居住人数：{{ applyHomeInfo.renterCount ||'暂无'}}</div>
                        </div>
                    </div>

                    <div v-if="relationList.length>0">
                        <div class="title">关联人员信息</div>
                        <div v-for="(item, index) in relationList" :key="index">
                            <div class="row-box">
                                <div class="col-box all-col">
                                    联系人：{{index+1}}<span v-show="item.isMaster=='1'">(主申请人)</span>
                                </div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">用户ID：{{ item.userId || '未绑定' }}</div>
                                <div class="col-box">姓：{{item.firstName}}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">名：{{item.lastName}}</div>
                                <div class="col-box">性别：{{item.sex}}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">电话：{{item.phone}}</div>
                                <div class="col-box">邮箱：{{item.email}}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="Object.keys(applyUserInfo).length>0">
                        <div class="title">个人信息</div>
                        <div class="row-box">
                            <div class="col-box">姓：{{ applyUserInfo.firstName }}</div>
                            <div class="col-box">名：{{ applyUserInfo.lastName }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">生日：{{ applyUserInfo.birthdayStr }}</div>
                            <div class="col-box">性别：{{ applyUserInfo.sex }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">婚姻状况：{{ applyUserInfo.isMarried }}</div>
                            <div class="col-box">电话：{{ applyUserInfo.phone }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">邮箱：{{ applyUserInfo.email }}</div>
                            <div class="col-box">身份证件：{{ applyUserInfo.cardType }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">证件号码：{{ applyUserInfo.cardNo }}</div>
                            <div class="col-box">签发国家：{{ applyUserInfo.cardCountry }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">护照过期日：{{ applyUserInfo.passportExpiryDateStr }}</div>
                        </div>
                    </div>


                    <div class="title">学生信息</div>
                    <div class="row-box">
                        <div class="col-box all-col">是否学生：{{ studentInfoJson.isStudent }}</div>
                    </div>

                    <div v-if="emergencyContact">
                        <div class="title">紧急联系人</div>
                        <div class="row-box">
                            <div class="col-box">姓名：{{ emergencyContact.name }}</div>
                            <div class="col-box">电话：{{ emergencyContact.phone }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box">邮箱：{{ emergencyContact.email }}</div>
                            <div class="col-box">地址：{{ emergencyContact.address }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">关系：{{ emergencyContact.relation }}</div>
                        </div>
                    </div>


                    <div  v-if="nowResidenceInfo">
                        <div class="title">现在住址</div>
                        <div class="row-box">
                            <div class="col-box all-col">现在你的居住情况： {{ nowResidenceInfo.situation }}</div>
                        </div>
                    </div>

                    <div v-if="oldResidenceInfo">
                        <div class="title">之前住址</div>
                        <div class="row-box">
                            <div class="col-box all-col">你之前的居住情况： {{ oldResidenceInfo.situation }}</div>
                        </div>
                    </div>


                    <div v-if="nowJobInfo">
                        <div class="title">现在工作</div>
                        <div class="row-box">
                            <div class="col-box all-col">现在你的工作情况：{{ nowJobInfo.situation }}</div>
                        </div>
                        <div  v-if="nowJobInfo.situation === '目前我有工作'||nowJobInfo.situation==='我经营自己的公司'">
                            <div class="row-box">
                                <div class="col-box all-col">公司名称：{{ nowJobInfo.workName }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">职位：{{ nowJobInfo.position }}</div>
                            </div>
                            <div v-if="nowJobInfo.situation === '我经营自己的公司'" class="row-box">
                                <div class="col-box all-col">行业类型：{{ nowJobInfo.industryType }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">公司地址：{{ nowJobInfo.workAddress }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">联系人：{{ nowJobInfo.contacts }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">联系人电话：{{ nowJobInfo.contactsPhone }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">工作性质：{{ nowJobInfo.jobNature }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">税前年收入：{{ nowJobInfo.annualIncome }}</div>
                            </div>
                            <div v-if="nowJobInfo.situation === '我经营自己的公司'">
                                <div class="row-box">
                                    <div class="col-box all-col">公司成立的日期：{{ nowJobInfo.establishmentTime }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">ACN编号：{{ nowJobInfo.ACN }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">ABN编号：{{ nowJobInfo.ABN }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">会计姓名：{{ nowJobInfo.accountingName }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">电话：{{ nowJobInfo.accountingPhone }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">律师姓名：{{ nowJobInfo.lawyerName }}</div>
                                </div>
                                <div class="row-box">
                                    <div class="col-box all-col">电话：{{ nowJobInfo.lawyerPhone }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="oldJobInfo">
                        <div class="title">之前工作</div>
                        <div class="row-box">
                            <div class="col-box all-col">你之前的工作情况：{{ oldJobInfo.situation }}</div>
                        </div>
                        <div v-if="oldJobInfo.situation!=='我之前没有被雇佣过'&&oldJobInfo.situation!=='我已经退休5年多'&&oldJobInfo.situation!=='我现在的工作已经5年多了'">
                            <div class="row-box">
                                <div class="col-box all-col">公司名称：{{ oldJobInfo.workName }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">职位：{{ oldJobInfo.position }}</div>
                                <div class="col-box">公司地址：{{ oldJobInfo.workAddress }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">联系人：{{ oldJobInfo.contacts }}</div>
                                <div class="col-box">联系人电话：{{ oldJobInfo.contactsPhone }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box">工作性质：{{ oldJobInfo.jobNature }}</div>
                                <div class="col-box">工作开始时间：{{ oldJobInfo.startDate }}</div>
                            </div>
                            <div class="row-box">
                                <div class="col-box all-col">税前年收入：{{ oldJobInfo.annualIncome }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="applyUserFile.length>0">
                        <div class="title">个人材料</div>
                        <div class="row-box" v-for="(item,index) in applyUserFile" :key="index">
                            <div class="col-box all-col">{{item.fileTypeName}}:
                                <a :href="item.filePath"
                                    target="_blank"
                                    class="urlLink"
                                    rel="noopener noreferrer"
                            >{{ item.fileName }}</a></div>
                        </div>
                    </div>
                    <div v-if="applyInfo&&applyInfo.applyType==1&&applySurvey">
                    <div class="title">申请条款</div>
                        <div class="row-box">
                            <div class="col-box all-col">你的租约是否曾被房东或中介终止： {{ applySurvey.leaseTerminated }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你是否曾被房东或中介拒绝接受物业： {{ applySurvey.leaseTerminated }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你是否欠其他房东或中介的债： {{ applySurvey.backRent }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你知道有什么原因会影响你未来的租金支付吗： {{ applySurvey.affectPaymentRent }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你还有其他待定的房产申请吗： {{ applySurvey.pendingHousingApplications }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你目前拥有房产吗： {{ applySurvey.owningHouse }}</div>
                        </div>
                        <div class="row-box">
                            <div class="col-box all-col">你是否考虑在此租约结束后或近期内再买房： {{ applySurvey.buyHousePlan }}</div>
                        </div>
                    </div>
                </div>

            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>

    export default {
        name: "applyDetails",
        props:{
            comprehensiveOrderInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        computed:{
            houseInfo(){
              return this.comprehensiveOrderInfo.orderHouseInfo
            },
            dnfJsonObj(){
              if (this.houseInfo.dnfJson){
                return JSON.parse(this.houseInfo.dnfJson)
              } else {
                return {}
              }
            },
            applyInfo() {
                return this.comprehensiveOrderInfo.applyInfo
            },
            applyStudentInfo() {
                return this.comprehensiveOrderInfo.applyStudentInfo
            },
            applyHomeInfo() {
                return this.comprehensiveOrderInfo.applyHomeInfo
            },
            applyUserInfo() {
                return this.comprehensiveOrderInfo.applyUserInfo ? (this.comprehensiveOrderInfo.applyUserInfo[0]?this.comprehensiveOrderInfo.applyUserInfo[0]:this.comprehensiveOrderInfo.applyUserInfo): {}
            },
            relationList() {
                return this.comprehensiveOrderInfo.applyRelation ?? []
            },
            applySurvey() {
                return this.comprehensiveOrderInfo.applySurvey
            },
            studentInfoJson() {
                if (!this.applyUserInfo.studentInfoJson) return {isStudent: '否'}
                return JSON.parse(this.applyUserInfo.studentInfoJson) || {}
            },
            emergencyContact() {
                return this.applyUserInfo.emergencyContact ? JSON.parse(this.applyUserInfo.emergencyContact) : {}
            },
            nowResidenceInfo() {
                return this.applyUserInfo.nowResidenceInfo ? JSON.parse(this.applyUserInfo.nowResidenceInfo) : {}
            },
            oldResidenceInfo() {
                return this.applyUserInfo.oldResidenceInfo ? JSON.parse(this.applyUserInfo.oldResidenceInfo) : {}
            },
            nowJobInfo() {
                return this.applyUserInfo.nowJobInfo ? JSON.parse(this.applyUserInfo.nowJobInfo) : {}
            },
            oldJobInfo() {
                return this.applyUserInfo.oldJobInfo ? JSON.parse(this.applyUserInfo.oldJobInfo) : {}
            },
            recommendPersonJson() {
                return this.applyUserInfo.recommendPersonJson ? JSON.parse(this.applyUserInfo.recommendPersonJson) : []
            },
            applyUserFile() {
                if(this.comprehensiveOrderInfo.applyUserFile.length>0){
                    return this.comprehensiveOrderInfo.applyUserFile
                }else{
                    return this.applyUserInfo.applyUserFile ?? []
                }
            },
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .title{
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: 5px;
        color:#000;
    }
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            width: 50%;
            height: 100%;
            word-break: break-word;
            /*background-color: red;*/
        }
        .all-col{
            width: 100%;
        }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
