<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="退款信息" name="1">
                <div>
                    <div class="row-box">
                        <div class="col-box">退款编号：{{ reviewOrderInfo.refundNo||'暂无'}}</div>
                        <div class="col-box">退款渠道：{{ reviewOrderInfo.payChannel||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">退款交易流水号： {{ reviewOrderInfo.refundTradeNo || '暂无' }}
                        </div>
                        <div class="col-box">退款金额： {{ priceUnit +' '+reviewOrderInfo.refundMoney }} </div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">退款说明：
                            {{ reviewOrderInfo.refundRemark }}
                        </div>
                        <div class="col-box">退款理由：
                            {{ reviewOrderInfo.refundReason }}
                        </div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">
                          上传附件：
                            <span v-if="reviewOrderInfo.fileInfos.length>0">
                                <a :href="item.filePath" v-for="item in reviewOrderInfo.fileInfos" target="_blank">{{item.fileName}}</a>
                            </span>
                            <span v-else>暂无</span>
                        </div>
                        <div class="col-box">退款时间：
                            {{ reviewOrderInfo.refundDate||'暂无' }}
                        </div>
                    </div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "refundInfo",
        props:{
            reviewOrderInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            },
          priceUnit:{
            type: String,
            required:true
          }
        },
        data(){
            return{
                activeName:'1'
            }
        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;

        .col-box{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 50%;
        height: 100%;
        word-break: break-word;
        /*background-color: red;*/
    }
    .all-col{
        width: 100%;
    }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
