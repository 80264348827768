<template>
    <div class="app" >
        <van-skeleton
        :row="20"
        :loading="loading"
        >
        <div class="header-title">
            <span>{{(userInfo.customerRegistInfo.nickName||'') +'-'+(productName||'')+'-'+(orderBaseInfo.orderNo||'')}}</span>
        </div>
<!--        订单信息-->
        <order-info :review-order-info="orderBaseInfo"></order-info>
<!--        用户信息-->
        <user-info  :userInfo="userInfo.customerRegistInfo"></user-info>
<!--        退款信息-->
        <refund-info v-if="orderBaseInfo.status == '退款成功'" :review-order-info="refundApplyInfo"  :price-unit="orderBaseInfo.payUnit"></refund-info>
<!--      业务类型及内容-->
        <type-and-cont :main-service="mainService" :add-service="valueAddService"></type-and-cont>
<!--        委派明细-->
        <delegate-details :order-delegate-info="orderDelegate"></delegate-details>
        <service-need-info
                v-if="orderApplyInfo.length>0"
                :title="formTitle + '需求'"
                :service-needs-info="orderApplyInfo"
        ></service-need-info>
<!--        申请明细-->
        <apply-details v-if="comprehensiveOrderInfo" :comprehensive-order-info="comprehensiveOrderInfo"></apply-details>
<!--        活动信息-->
        <new-activity-info v-if="activityDetails && activityDetails.activityName" :activity-details="activityDetails"></new-activity-info>
        <use-coupon :used-coupon-info="orderCouponInfo"></use-coupon>
        <login-history :login-info="customerLoginInfo"></login-history>
        <examine-dialog v-if="examineVisible"
                        @refreshData="onLoad"
                        :review-info="newOrderInfo"
                        :pushNo="pushNo"
                        :arg="arg"
                        :show-examine-dialog-visible.sync="examineVisible">
        </examine-dialog>
        <delegate-dialog v-if="delegateVisible"
                        @refreshData="onLoad"
                         :orderNo = "orderNo"
                         :isSale="isSale"
                         :pushNo="pushNo"
                         :show-delegate-dialog-visible.sync="delegateVisible"
        ></delegate-dialog>
            <div class="footer-btn" v-if="orderBaseInfo&&isView">  <!--v-if="false"--> <!--@click="handleFooter"-->
                <van-button
                            v-if="orderBaseInfo.orderMoney!='0'&&isRight==1&&orderBaseInfo.isFinanceProcessAuth==1"
                             class="van-button"
                             @click="examine(1)"
                             size="mini"
                             type="warning">财务审核</van-button>
                <van-button
                            v-if="isRight==2&&orderBaseInfo.isEffectivenessProcessAuth==1"
                            style="margin-left: 10px"
                             class="van-button"
                             @click="examine(2)"
                             size="mini"
                             type="warning">有效性审核</van-button>

            </div>
            <div class="footer-delegate" v-if="orderBaseInfo&&isView&&orderBaseInfo.isDelegateAuth==1">
                <van-button
                            @click="delegate"
                            size="mini"
                            type="warning">委派</van-button>
            </div>
    </van-skeleton>
    </div>
</template>

<script>
    import orderInfo from "./comp/orderInfo";
    import userInfo from "./comp/userInfo";
    import refundInfo from "./comp/refundInfo";
    import typeAndCont from "./comp/typeAndCont";
    import delegateDetails from "./comp/delegateDetails";
    import serviceNeedInfo from "./comp/serviceNeedInfo";
    import applyDetails from "./comp/applyDetails";
    import newActivityInfo from "./comp/newActivityInfo";
    import useCoupon from "./comp/useCoupon";
    import loginHistory from "./comp/loginHistory";
    import examineDialog from "./comp/examineDialog";
    import delegateDialog from "./comp/delegateDialog";
    import { getOrderInfo,toValidate,
            getNewOrderInfo,
            getOrderInfoNoPushNo,
            getNewOrderInfoNoPushNo } from '@/api/myOrderDetails'

    export default {
        name: "index",
        components:{
            orderInfo,
            userInfo,
            refundInfo,
            typeAndCont,
            delegateDetails,
            serviceNeedInfo,
            applyDetails,
            newActivityInfo,
            useCoupon,
            loginHistory,
            examineDialog,
            delegateDialog
        },
        data(){
            return{
                loading:false,
                activeName:'1',
                formTitle:'',
                examineVisible:false,
                arg:1,//arg为1财务审核，arg为2有效性审核
                delegateVisible:false,
                orderBaseInfo:{},
                userInfo:{
                    customerRegistInfo:{}
                },
                productName:'',//产品名称
                refundApplyInfo:{},//退款信息
                mainService:{},
                valueAddService:[],
                orderDelegate:{},//委派明细
                orderApplyInfo:[],
                comprehensiveOrderInfo:{},
                activityDetails:{},
                orderCouponInfo:{},
                customerLoginInfo:{},
                form: {
                    id: null,
                    refundNo: null, // 退款编号
                    refundReason: null, // 订单申请退款理由
                    refundType: null, // 订单退款方式
                    refundMoney: null, // 订单退款金额
                    refundRemark: null, // 订单退款说明
                    fileInfos: [], // 上传附件
                    remark: null, // 审核评论
                    processStatus: null, // 审核状态
                    refundCustomerAccount: null, // 退款客户账户
                    refundCompanyAccount: null, // 退款公司账户
                    refundDate: null // 退款时间
                },
                newOrderInfo:{},
                reviewInfo:{},
                orderNo: '',
                pushNo: '',
                isRight:'',
                isView:true
            }
        },
        computed:{
          isSale(){
              if(this.orderDelegate&&this.orderDelegate.saleName){
                  return false
              }else{
                  return true
              }
          }
        },
        created() {
            this.orderNo = this.$route.query.orderNo
            this.pushNo = this.$route.query.pushNo?this.$route.query.pushNo:''
            this.isRight = this.$route.query.isRight?this.$route.query.isRight:''
            if(this.pushNo){
                this.onLoad()
            }else{
                this.isView = false
                this.onNewLoad()
            }
        },
        methods:{
            onLoad(){
                this.loading = true
                getOrderInfo({
                    orderNo:this.orderNo,
                    pushNo:this.pushNo
                }).then(res=>{
                    this.comprehensiveOrderInfo = res.data
                    this.productName = res.data.productName
                    this.formTitle = this.productName
                    this.orderBaseInfo = res.data.orderBaseInfo
                    this.orderApplyInfo = res.data.orderApplyInfo
                    this.orderProcessInfo = res.data.orderProcessInfo
                    this.applyRelation = res.data.applyRelation
                    this.mainService = res.data.mainService
                    this.orderDelegate = res.data.orderDelegate
                    this.valueAddService = res.data.valueAddService
                    this.orderCouponInfo = res.data.orderCouponInfo
                    this.orderFriendInfo = res.data.orderFriendInfo
                    this.customerLoginInfo = res.data.customerLoginInfo
                    this.refundApplyInfo = res.data.refundApplyInfo
                    this.userInfo = res.data.userInfo
                    this.activityDetails = res.data.activityDetails
                    this.orderHouseInfo = res.data.orderHouseInfo
                    this.loading = false
                    getNewOrderInfo({
                        orderNo:this.orderNo,
                        pushNo:this.pushNo
                    }).then(res=>{
                        this.newOrderInfo = res.data
                    })
                })
            },
            onNewLoad(){
                this.loading = true
                getOrderInfoNoPushNo({
                    orderNo:this.orderNo
                }).then(res=>{
                    this.comprehensiveOrderInfo = res.data
                    this.productName = res.data.productName
                    this.formTitle = this.productName
                    this.orderBaseInfo = res.data.orderBaseInfo
                    this.orderApplyInfo = res.data.orderApplyInfo
                    this.orderProcessInfo = res.data.orderProcessInfo
                    this.applyRelation = res.data.applyRelation
                    this.mainService = res.data.mainService
                    this.orderDelegate = res.data.orderDelegate
                    this.valueAddService = res.data.valueAddService
                    this.orderCouponInfo = res.data.orderCouponInfo
                    this.orderFriendInfo = res.data.orderFriendInfo
                    this.customerLoginInfo = res.data.customerLoginInfo
                    this.refundApplyInfo = res.data.refundApplyInfo
                    this.userInfo = res.data.userInfo
                    this.activityDetails = res.data.activityDetails
                    this.orderHouseInfo = res.data.orderHouseInfo
                    this.loading = false
                    getNewOrderInfoNoPushNo({
                        orderNo:this.orderNo,
                    }).then(res=>{
                        this.newOrderInfo = res.data
                    })
                })
            },
            examine(arg){
                console.log('arg==>',arg);
                if(arg==1){
                    this.arg = arg
                    this.examineVisible = true
                }
                else if(arg==2){
                    this.arg = arg
                    this.examineVisible = true
                }
            },
            delegate(){
                this.delegateVisible = true
            }
        }
    }
</script>

<style scoped lang="scss">
        @media screen and (min-width: 800px) {
            .app {
                box-sizing: border-box;
                padding: 30px;
                width: 100% !important;
                background-color: #f9f9f9 !important;
                margin-top:1000px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) scale(1.5);
                max-width: 1200px;
            }
        }
    .header-title{
        font-size: 16px;
        font-weight: 700;
        padding: 15px;
    }
    .footer-btn{
        width: 100%;
        height: 60px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        /*margin-top: 10px;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*margin-bottom: 5px;*/
    }
    .footer-delegate{
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 5px;
        right: 0;
        text-align: center;
    }
    .van-button{
        min-width: 100px;
        height: 40px;
        font-size: 14px;
        border-radius: 4px;
    }
</style>
