<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="登录历史" name="1">
                <div>
                    <div class="row-box">
                        <div class="col-box">登录时间：{{loginInfo.loginTime||'暂无'}}</div>
                        <div class="col-box">登录版本：{{loginInfo.version||'暂无'}}</div>
                    </div>
                    <div class="row-box">
                        <div class="col-box">IP地址：{{loginInfo.ip||'暂无'}}</div>
                        <div class="col-box">登录设备：{{loginInfo.equipment||'暂无'}}</div>
                    </div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "loginHistory",
        props:{
            loginInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 50%;
        height: 100%;
        word-break: break-word;
        /*background-color: red;*/
    }
    .all-col{
        width: 100%;
    }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
