<template>
    <div>
        <van-collapse v-model="activeName" v-if="serviceNeedsInfo.length > 0" accordion>
            <van-collapse-item :title="title" name="1">
                <div class="row-box" v-for="item in serviceNeedsInfo" :key="item.title" >
                    <div class="col-box">{{`${item.title} : ${item.content||'暂无'}`}}</div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "serviceNeedInfo",
        props:{
            title: {
                type: String,
                default: '服务需求'
            },
            serviceNeedsInfo: {
                type: Array,
                required: true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{
        }
    }
</script>

<style scoped lang="scss">
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;
        .col-box{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        height: 100%;
        word-break: break-word;
        /*background-color: red;*/
    }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
