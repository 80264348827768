<template>
    <div>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="委派明细" name="1">
                <div>
                    <div class="title">市场端</div>
                    <div class="row-box">
                        <div class="col-box all-col">市场组顾问：
                            <span v-if="orderDelegateInfo.userNumber">{{orderDelegateInfo.userNumber +' - '+orderDelegateInfo.saleName||'无'}}</span>
                            <span v-else>暂无</span>
                        </div>
                    </div>
                    <div v-if="orderDelegateInfo.flowList&&orderDelegateInfo.flowList.length>0">
                        <div class="title">业务端</div>
                        <div class="row-box" v-for="(item,index) in orderDelegateInfo.flowList">
                            <div class="col-box">业务流：{{item.flowType||'无'}}</div>
                            <div class="col-box">业务组顾问：{{item.adviserName||'无'}}</div>
                            <div class="col-box">客单编号：{{item.flowNo||'无'}}</div>
                        </div>
                    </div>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
    export default {
        name: "delegateDetails",
        props:{
            orderDelegateInfo:{
                type:Object,
                default(){
                    return{}
                },
                required:true
            }
        },
        data(){
            return{
                activeName:'1'
            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">
    .title{
        font-weight: 700;
        color:#000;
    }
    .row-box{
        display: flex;
        justify-content: space-between;
        color:#000;

        .col-box{
            padding-top: 10px;
            padding-bottom: 10px;
            width: 33%;
            height: 100%;
            word-break: break-word;
            /*background-color: red;*/
        }
        .all-col{
            width: 100%;
        }
    }

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>
